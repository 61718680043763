import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { AppState } from "../../store";
import { getInstallerDescription } from "../../actions/download/actions";
import { INSTALLER_CODE, SERVICE_CODES } from "../../common/constants";
import { dictionaryGet } from "../../common/functions";
import { Spinner } from "reactstrap";

import { Redirect } from "react-router";

import useUnderMaintenance from "../../helpers/customHooks/useUnderMaintenance";
import { useMaintenanceStatus } from "../../helpers/customHooks/useMaintenanceStatus";

import { renderCards, renderQlikHistory } from "./functions/renderFunctions";

interface Installer {
  BI: any;
  QLIK: any;
}

export function Download() {
  const current = useSelector((state: AppState) => state.user.current);
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const [installers, setInstallers] = useState<Installer | null>(null);
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({
    BI: false,
    BI_UTILITIES: false,
    BI_MANUAL: false,
    BI_UTILITIES_MANUAL: false
  });
  const [isDataLoading, setIsDataLoading] = useState(true);
  const dictionary = useSelector((state: AppState) => state.dictionary);
  const { data: maintenanceResponse } = useMaintenanceStatus();
  const routeUnderMaintenance = useUnderMaintenance(maintenanceResponse);

  useEffect(() => {
    let installerObj: any = {};
    if (current.selectedCompany !== null) {
      let idCompany = current.selectedCompany.company.id;
      setIsDataLoading(true);
      thunkDispatch(getInstallerDescription(INSTALLER_CODE.BI, idCompany))
        .then((ress: any) => {
          thunkDispatch(getInstallerDescription(INSTALLER_CODE.QLIK, idCompany))
            .then((res: any) => {
              installerObj.QLIK = JSON.parse(res.data);
              installerObj.BI = JSON.parse(ress.data);
              setInstallers(installerObj);
              setIsDataLoading(false);
            })
            .catch(() => {
              setIsDataLoading(false);
            });
        })
        .catch(() => {
          setIsDataLoading(false);
        });
    } else {
      const userDataLoading = !current || !current.user;
      setIsDataLoading(userDataLoading);
    }
  }, [current.selectedCompany]);

  if (isDataLoading) {
    return <Spinner />;
  }

  if (!current.selectedCompany) {
    return <Redirect to="/home" />;
  }

  if (
    current.selectedCompany.service.findIndex((serv) => {
      return serv.code === SERVICE_CODES.BI;
    }) !== -1
  ) {
    return (
      <>
        <div style={{ padding: "20px 20px 0 20px" }}>
          <div style={{ marginBottom: "20px" }}>
            {dictionaryGet(dictionary, "global.console.download.page_caption")}
          </div>
        </div>
        {renderQlikHistory(installers, dictionary)}
        <div style={{ padding: "20px" }}>
          {" "}
          {renderCards(
            installers,
            dictionary,
            current,
            thunkDispatch,
            setIsLoading,
            routeUnderMaintenance,
            isLoading
          )}
        </div>
      </>
    );
  } else {
    return (
      <div style={{ padding: "20px" }}>
        <div>
          {dictionaryGet(dictionary, "global.console.download.page_caption")}
        </div>
        {renderCards(
          installers,
          dictionary,
          current,
          thunkDispatch,
          setIsLoading,
          routeUnderMaintenance,
          isLoading
        )}
      </div>
    );
  }
}
