import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link, Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faMailBulk, faUserLock, faUsers, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { dictionaryGet } from '../../common/functions';
import { AppState } from '../../store';

const nav = {
  items: [
    {
      id: 0,
      name: 'Home',
      url: '/home',
      icon: 'home',
      subName: "",
      mainMenu: true
    },
    {
      id: 1,
      name: 'global.console.download.title',
      url: '/download',
      icon: faDownload,
      subName: 'global.console.download.description',

      mainMenu: true
    },
    {
      id: 1,
      name: 'global.console.invitations.title',
      url: '/inviti',
      icon: faMailBulk,
      subName: 'global.console.invitations.description',
      mainMenu: true
    },
    {
      id: 2,
      name: 'global.console.users_management.title',
      url: '/utenti',
      icon: faUsers,
      subName: 'global.console.users_management.description',
      mainMenu: true
    },
    {
      id: 3,
      name: 'global.console.authorizations.title',
      url: '/autorizzazioni',
      icon: faUserLock,
      subName: "global.console.authorizations.description",
      mainMenu: true
    },
    {
      id: 4,
      name: 'global.console.authorization_verification.title',
      url: '/verifica-autorizzazioni',
      icon: faUserCheck,
      subName: 'global.console.authorization_verification.description',
      mainMenu: true
    },
  ]
};

// const findRouteName = (url: string) => routes[url];

const getPaths = (pathname: any) => {
  const paths = ['/'];
  if (pathname === '/') return paths;
  pathname.split('/').reduce((prev: any, curr: any, index: any) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });
  return paths;
};

const getNavFromPath = (pathname: any) => {
  var n = null;
  for (var i = 0; i < nav.items.length; i++) {
    if (nav.items[i].url === pathname) {
      n = nav.items[i];
    }
  }
  return n;
};

const getParameterByName = (name: any, url: any) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};


const BreadcrumbsItem = (allProps: any) => {
  const dictionary = useSelector((state: AppState) => state.dictionary);

  const navItem = getNavFromPath(allProps.match.url);
  if (navItem != null) {
    if (navItem.name == 'Hai cercato ') {
      let term = getParameterByName("term", window.location);
      return (
        <BreadcrumbItem>
          <a style={{ color: "inherit" }} href="javascript:void(0)" onClick={allProps.history.goBack}><FontAwesomeIcon icon={faHome} className="font-2xl" style={{ fontSize: "27px" }} /></a><span
            style={{ fontSize: "22px" }}>Hai cercato "{term}"</span>
        </BreadcrumbItem>
      );
    }
    if (navItem.name == 'Home') {
      return (
        allProps.match.isExact ?
          (
            <BreadcrumbItem style={{ textAlign: "center" }}>
              <span style={{ textTransform: "uppercase", fontSize: "20px", verticalAlign: "top", color: "rgb(0, 80, 117)" }}>{navItem.name}</span>
            </BreadcrumbItem>
          ) :
          (
            <BreadcrumbItem>
              <Link to={allProps.match.url || ''}>
                {navItem.name}
              </Link>
            </BreadcrumbItem>
          )
      );
    } else {
      // let name = _.get(dictionary, navItem.name);
      return (
        allProps.match.isExact ?
          (
            <BreadcrumbItem active>
              <div style={{ display: "inline-grid" }}>
                <span style={{ textTransform: "uppercase", fontSize: "20px", verticalAlign: "top", color: "rgb(0, 80, 117)" }}>{ dictionaryGet(dictionary, navItem.name) }</span>
                <span style={{ color: "rgb(90, 104, 114)", fontSize: "12px" }}> { dictionaryGet(dictionary, navItem.subName) }</span>
              </div>
            </BreadcrumbItem >
          ) :
          (
            <BreadcrumbItem>
              <Link to={allProps.match.url || ''}>
                { dictionaryGet(dictionary, navItem.name) }
              </Link>
            </BreadcrumbItem>
          )
      );
    }
  }
  return null;
};

const Breadcrumbs = ({ ...rest }) => {

  const paths = getPaths(rest.location.pathname);
  const items = paths.map((path, i) => <Route key={i++} path={path}
    render={(props) => <BreadcrumbsItem {...props} percorso={path} />} />);
  return (
    <Breadcrumb className="breadcrumb-container" style={(rest.location.pathname == '/home' ? { display: "none" } : {})}>
      {items}
    </Breadcrumb>
  );
};

export default function BreadCrumb(props: any) {
  return (
    <div>
      <Route path="/:path" render={() => <Breadcrumbs {...props} />} />
    </div>
  );
}
