import { getAccessTokenCookie } from "../../common/functions";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../store";
import { AnyAction } from "redux";
import Axios from "axios";
import { URL, LAMBDA_URL } from "../../common/constants";
import { ICurrent } from "../../store/user/types";
import { Installer } from "../../containers/Download/types/download";

export function getInstallerBi(
  installers: Installer | null,
  current: ICurrent,
  idCompany: number,
  companyGuid: string
): any {
  const AuthStr = "Bearer ".concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/installer/BI_web/${idCompany}`;
  return async (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>
  ): Promise<any> => {
    const fiscalCode = current.selectedCompany
      ? current.selectedCompany.company.fiscalCode
      : null;

    const biVersion = installers ? installers.BI.version : null;

    const biUtilitiesVersion = installers
      ? installers.BI.biUtilitiesVersion
      : null;

    return Axios.get(url, {
      headers: { Authorization: AuthStr, "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.data.result === "OK") {
          return dispatch(
            invokeLambdaDownload(
              companyGuid,
              fiscalCode,
              biVersion,
              biUtilitiesVersion
            )
          );
        }
      })
      .catch((error) => {
        return error;
      });
  };
}

export function invokeLambdaDownload(
  companyGuid: string,
  fiscalCode: string | null,
  biVersion: string | null,
  biUtilitiesVersion: string | null
) {
  const url = `${LAMBDA_URL}/bi_web_download_url/${companyGuid}?fiscalCode=${fiscalCode}&biVersion=${biVersion}&biUtilitiesVersion=${biUtilitiesVersion}`;
  return async (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>
  ): Promise<void> => {
    return Axios.get(url)
      .then((response: any) => {
        document.location.href = response.data;
        return "";
      })
      .catch((err) => {
        return err;
      });
  };
}

export function getInstallerBiVersion(
  idCompany: number,
  companyGuid: string,
  current: ICurrent,
  staticVersion: string | null
): any {
  const AuthStr = "Bearer ".concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/installer/BI/${idCompany}`;
  return async (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>
  ): Promise<any> => {
    const fiscalCode = current.selectedCompany
      ? current.selectedCompany.company.fiscalCode
      : null;
    return Axios.get(url, {
      headers: { Authorization: AuthStr, "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.data.result === "OK") {
          return dispatch(
            invokeLambdaDownloadVersion(companyGuid, fiscalCode, staticVersion)
          );
        }
      })
      .catch((error) => {
        return error;
      });
  };
}

export function invokeLambdaDownloadVersion(
  companyGuid: string,
  fiscalCode: string | null,
  staticVersion: string | null
) {
  const url = `${LAMBDA_URL}/bi_download_url/${companyGuid}?fiscalCode=${fiscalCode}&biVersion=${staticVersion}`;
  return async (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>
  ): Promise<void> => {
    return Axios.get(url)
      .then((response: any) => {
        document.location.href = response.data;
        return "";
      })
      .catch((err) => {
        return err;
      });
  };
}

export function getInstallerDescription(
  installerCode: "BI" | "qlik",
  idCompany: number
) {
  const AuthStr = "Bearer ".concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/installer/description/${installerCode}/${idCompany}`;
  return async (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>
  ): Promise<any> => {
    return Axios.get(url, {
      headers: { Authorization: AuthStr, "Content-Type": "application/json" },
    }).then((response) => {
      return response.data;
    });
  };
}

export function getInstallerQlik(idCompany: number) {
  const AuthStr = "Bearer ".concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/installer/qlik/${idCompany}`;

  return async (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>
  ): Promise<void> => {
    return Axios.get(url, { headers: { Authorization: AuthStr } })
      .then((response) => {
        return dispatch(getInstallerQlikSuccess(response));
      })
      .catch((error) => {
        dispatch(getInstallerQlikFailure(error));
      });
  };
}

export function getInstallerQlikVersion(idCompany: number, fileName: string) {
  const AuthStr = "Bearer ".concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/installer/qlik/${idCompany}/version?fileName=${fileName}`;

  return async (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>
  ): Promise<void> => {
    return Axios.get(url, { headers: { Authorization: AuthStr } })
      .then((response) => {
        return dispatch(getInstallerQlikSuccess(response));
      })
      .catch((error) => {
        dispatch(getInstallerQlikFailure(error));
      });
  };
}

export function getBIManualUrl(idCompany: number) {
  const AuthStr = "Bearer ".concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/installer/BI/manual/${idCompany}`;
  return new Promise((resolve: any, reject: any) => {
    return Axios.get(url, { headers: { Authorization: AuthStr } })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getBIUtils(idCompany: number) {
  const AuthStr = "Bearer ".concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/installer/BI/utils/${idCompany}`;
  return new Promise((resolve: any, reject: any) => {
    return Axios.get(url, { headers: { Authorization: AuthStr } })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getInstallerQlikSuccess(response: any): any {
  return {
    type: "",
    payload: response.data,
  };
}

function getInstallerQlikFailure(error: any): any {
  return {
    type: "",
    payload: error.response,
  };
}
