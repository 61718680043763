import React, { useState } from "react";

import { getInstallerBiVersion } from "../../../actions/download/actions";
import { INSTALLER_CODE } from "../../../common/constants";
import { dictionaryGet } from "../../../common/functions";
import {
  RenderBIHistoryProps,
  RenderQlikHistoryContentProps,
  RenderQlikPostgresSqlProps,
} from "../types/download";
import { downloadQlikHistoryVersion } from "./downloadQlikFunctions";
import SpinnerBadge from "../common/SpinnerBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

export const RenderQlikHistoryContent = ({
  installers,
  dictionary,
  current,
  thunkDispatch,
}: RenderQlikHistoryContentProps) => {
  const [loadingStates, setLoadingStates] = useState<boolean[]>(
    new Array(installers ? installers.QLIK.legacy.length : 3).fill(false)
  );

  if (
    installers != null &&
    installers.QLIK.legacy != null &&
    installers.QLIK.legacy.length > 0
  ) {
    let versions = installers.QLIK.legacy.map((item: any, index: number) => {
      return (
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <FontAwesomeIcon
            size="sm"
            style={{ marginTop: "5px" }}
            icon={faDownload}
          />

          <p key={item.fileName}>
            <a
              onClick={() => {
                setLoadingStates((prev) => {
                  const newStates = [...prev];
                  newStates[index] = true;
                  return newStates;
                });

                downloadQlikHistoryVersion(
                  INSTALLER_CODE.BI,
                  item.fileName,
                  current,
                  thunkDispatch
                ).then(() => {
                  setLoadingStates((prev) => {
                    const newStates = [...prev];
                    newStates[index] = false;
                    return newStates;
                  });
                });
              }}
            >
              {dictionaryGet(
                dictionary,
                "global.console.download.download_version"
              )}{" "}
              {item.version}
              {loadingStates[index]
                ? SpinnerBadge(
                    dictionary,
                    "global.console.download.downloading"
                  )
                : null}
            </a>
          </p>
        </div>
      );
    });

    return <>{versions}</>;
  }

  return null;
};

export const RenderQlikPostgreSqlSetup = ({
  installers,
  dictionary,
  current,
  thunkDispatch,
}: RenderQlikPostgresSqlProps) => {
  const [loadingStates, setLoadingStates] = useState<boolean[]>(
    new Array(installers ? installers.QLIK.database.length : 1).fill(false)
  );

  if (
    installers != null &&
    installers.QLIK.database != null &&
    installers.QLIK.database.length > 0
  ) {
    let versions = installers.QLIK.database.map((item: any, index: number) => {
      return (
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <FontAwesomeIcon
            size="sm"
            style={{ marginTop: "5px" }}
            icon={faDownload}
          />

          <p key={item.fileName}>
            <a
              onClick={() => {
                setLoadingStates((prev) => {
                  const newStates = [...prev];
                  newStates[index] = true;
                  return newStates;
                });

                downloadQlikHistoryVersion(
                  INSTALLER_CODE.BI,
                  item.fileName,
                  current,
                  thunkDispatch
                ).then(() => {
                  setLoadingStates((prev) => {
                    const newStates = [...prev];
                    newStates[index] = false;
                    return newStates;
                  });
                });
              }}
            >
              {dictionaryGet(dictionary, "global.console.download.title")}{" "}
              {item.version}
              {loadingStates[index]
                ? SpinnerBadge(
                    dictionary,
                    "global.console.download.downloading"
                  )
                : null}
            </a>
          </p>
        </div>
      );
    });

    return <>{versions}</>;
  }
  return null;
};

export const RenderBIHistory = ({
  dictionary,
  current,
  thunkDispatch,
}: RenderBIHistoryProps) => {
  const staticVersion = "2023.02.03";
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div style={{ display: "flex", gap: "0.5rem" }}>
      <FontAwesomeIcon
        size="sm"
        style={{ marginTop: "5px" }}
        icon={faDownload}
      />
      <p>
        <a
          onClick={() => {
            if (current.selectedCompany !== null) {
              setIsLoading(true);
              return thunkDispatch(
                getInstallerBiVersion(
                  current.selectedCompany.company.id,
                  current.selectedCompany.company.guid,
                  current,
                  staticVersion
                )
              ).then(() => {
                setIsLoading(false);
              });
            }
          }}
        >
          {dictionaryGet(
            dictionary,
            "global.console.download.download_version"
          )}{" "}
          {staticVersion}{" "}
          {isLoading
            ? SpinnerBadge(dictionary, "global.console.download.downloading")
            : null}
        </a>
        <br />
      </p>
    </div>
  );
};
