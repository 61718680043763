import { dictionaryGet, translationStringReplace } from "../../../common/functions";

enum ExpirationStatus {
    ACTIVE = "ACTIVE",
    EXPIRING_SOON = "EXPIRING_SOON",
    EXPIRED = "EXPIRED"
}

const getExpirationStatus = (creationDate: Date | null, dictionary: any) => {
    if (!creationDate) return { message: "-", expired: false, status: ExpirationStatus.ACTIVE };

    const oneWeekMs = 7 * 24 * 60 * 60 * 1000; 
    const expirationDate = new Date(new Date(creationDate).getTime() + oneWeekMs);
    const today = new Date();
    
    const daysLeft = Math.ceil((expirationDate.getTime() - today.getTime()) / (24 * 60 * 60 * 1000));

    let status: ExpirationStatus;
    let message: string;
    let expired: boolean;

    if (daysLeft > 1) {
        status = ExpirationStatus.ACTIVE;
        message = translationStringReplace(
            "daysLeft",
            dictionaryGet(dictionary, "global.console.invitations.expires_in_days"), 
            daysLeft.toString()
        );
        expired = false;
    } else if (daysLeft === 1) {
        status = ExpirationStatus.EXPIRING_SOON;
        message = dictionaryGet(dictionary, "global.console.invitations.expires_soon");
        expired = false;
    } else {
        status = ExpirationStatus.EXPIRED;
        message = dictionaryGet(dictionary, "global.console.invitations.expired");
        expired = true;
    }

    return { message, expired, status };
};

export { getExpirationStatus, ExpirationStatus };
export default getExpirationStatus;
