import React from "react";
import { ModalHeader, ModalBody, Modal, ModalFooter, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../store';
import { AnyAction } from 'redux';
import { changeCompanyAuthorizations } from '../../actions/authgroups/actions';
import { AUTH_ACTIONS } from '../../common/constants';
import { dictionaryGet, translationStringReplace } from '../../common/functions';





type Props = {
  isModalOpen: boolean,
  closeModal: Function,
  selectedServ: any,
  idCompany: number,
  actionIndex: number,
  principal: any
}

export default function ModaleCompanyAuths(props: Props) {
  const dictionary = useSelector((state: AppState) => state.dictionary);
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();

  const submit = () => {
    thunkDispatch(changeCompanyAuthorizations(props.idCompany, props.selectedServ.id, props.actionIndex)).then(() => {
      props.closeModal(props.idCompany, props.selectedServ.id, props.principal);
    });
  };
  const getVisualText = () => {
    switch (AUTH_ACTIONS[props.actionIndex]) {
      case "ALLOW":
        return  dictionaryGet(dictionary, "global.console.authorizations.allow") ;
      case "DENY":
        return dictionaryGet(dictionary, "global.console.authorizations.deny");
    }
  };

  const renderModalBody = () => {
    return (
      <>
        <ModalHeader>
          <FontAwesomeIcon icon={faQuestionCircle} color={'#fbc863'} />{getVisualText()}?
        </ModalHeader>
        <ModalBody style={{ marginLeft: '15px', marginRight: '15px' }}>
        {translationStringReplace("serviceName", dictionaryGet(dictionary, "global.console.authorizations.auth_sheets_message"), props.selectedServ.name, true)}
        </ModalBody>
      </>
    );
  };

  return (
    <Modal isOpen={props.isModalOpen} toggle={() => { props.closeModal(props.idCompany, props.selectedServ.id, props.principal); }} size='md' style={{ width: 'auto', height: 'auto', maxWidth: '40vw' }}>
      {renderModalBody()}
      <ModalFooter>
        <Button className="btn-form-secondary" onClick={() => { props.closeModal(props.idCompany, props.selectedServ.id, props.principal); }}>{dictionaryGet(dictionary, "global.console.cancel")}</Button>
        <Button className="btn-form-primary" color="primary" onClick={() => { submit(); }}>OK</Button>
      </ModalFooter>
    </Modal>
  );
}