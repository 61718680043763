import { INSTALLER_CODE } from "../../../common/constants";
import { ICurrent } from "../../../store/user/types";
import {
  getBIManualUrl,
  getBIUtils,
  getInstallerBi,
  getInstallerQlik,
  getInstallerQlikVersion,
} from "../../../actions/download/actions";
import { Installer } from "../types/download";

export const downloadBiManual = (current: ICurrent) => {
  if (current.selectedCompany !== null) {
    let idCompany = current.selectedCompany.company.id;
    getBIManualUrl(idCompany).then((ress: any) => {
      window.open(ress.data);
    });
  }
};

export const downloadBiUtilsManual = (current: ICurrent) => {
  if (current.selectedCompany !== null) {
    let idCompany = current.selectedCompany.company.id;
    getBIManualUrl(idCompany).then((ress: any) => {
      window.open(ress.data);
    });
  }
};

export const downloadBIUtils = async (current: ICurrent, setIsLoading: React.Dispatch<React.SetStateAction<Record<string, boolean>>>) => {
  if (current.selectedCompany !== null) {
    const idCompany = current.selectedCompany.company.id;
    
    setIsLoading((prev) => ({ ...prev, BI_UTILITIES: true }));

    try {
      const ress: any = await getBIUtils(idCompany);
      window.open(ress.data);
    } catch (error) {
      console.error("Error downloading BI Utilities:", error);
    } finally {
      setIsLoading((prev) => ({ ...prev, BI_UTILITIES: false }));
    }
  }
};

export const DownloadVersion = (
  type: INSTALLER_CODE,
  fileName: "",
  current: ICurrent,
  thunkDispatch: any
) => {
  if (current.selectedCompany !== null) {
    return thunkDispatch(
      getInstallerQlikVersion(current.selectedCompany.company.id, fileName)
    ).then((res: any) => {
      window.open(res.payload.data);
    });
  }
};

export const download = (
  type: INSTALLER_CODE,
  installers: Installer | null,
  current: ICurrent,
  thunkDispatch: any,
  setIsLoading: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
) => {
  if (current.selectedCompany !== null) {
    if (type === INSTALLER_CODE.BI) {
      setIsLoading((prev) => ({ ...prev, BI: true }));
      return thunkDispatch(
        getInstallerBi(
          installers,
          current,
          current.selectedCompany.company.id,
          current.selectedCompany.company.guid
        )
      ).then((res: any) => {
        setIsLoading((prev) => ({ ...prev, BI: false })); 
      });
    } else {
      setIsLoading((prev) => ({ ...prev, BI_UTILITIES: true })); 
      return thunkDispatch(
        getInstallerQlik(current.selectedCompany.company.id)
      ).then((res: any) => {
        window.open(res.payload.data);
        setIsLoading((prev) => ({ ...prev, BI_UTILITIES: false })); 
      });
    }
  }
};
