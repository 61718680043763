import { AppState } from '../../store/index';
import { Actions } from '../index';
import { TInvitations } from './types';
import axios from 'axios';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { URL } from '../../common/constants';
import { getAccessTokenCookie, checkAuthState } from '../../common/functions';
import { refreshToken } from "../user/actions";
// import { createHeader, getMobileUrlCookie } from '../../common/functions';

export function hasInvitations(companyId: number): ThunkAction<Promise<any>, AppState, {}, AnyAction> {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/invitation/hasInvitations/${companyId}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.get(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      return dispatch(hasInvitationsSuccess(response));
    }).catch((error) => {
      dispatch(hasInvitationsFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(hasInvitations(companyId), dispatch);
        }
      }
      checkAuthState(error.response.status);
    });
  };
}

function hasInvitationsSuccess(response: any): TInvitations {
  return {
    type: Actions.HAS_INVITATIONS_SUCCESS,
    payload: response.data
  };
}

function hasInvitationsFailure(error: any): TInvitations {
  return {
    type: Actions.HAS_INVITATIONS_FAILURE,
    payload: error.response
  };
}

export function countInvitations(serviceId: number, companyId: number, page: number, otherProps?: string): ThunkAction<Promise<any>, AppState, {}, AnyAction> {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/invitation/count/${serviceId}/${companyId}?${otherProps}`;

  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.get(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      return dispatch(countInvitationsSuccess(response));
    }).catch((error) => {
      dispatch(countInvitationsFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(countInvitations(serviceId, companyId, page, otherProps), dispatch);
        }
      }
      checkAuthState(error.response.status);
    });
  };
}

function countInvitationsSuccess(response: any): TInvitations {
  return {
    type: Actions.COUNT_INVITATIONS_SUCCESS,
    payload: response.data
  };
}

function countInvitationsFailure(error: any): TInvitations {
  return {
    type: Actions.COUNT_INVITATIONS_FAILURE,
    payload: error.response
  };
}

export function searchInvitations(serviceId: number, companyId: number, page: number, otherProps?: string): ThunkAction<Promise<any>, AppState, {}, AnyAction> {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = otherProps !== undefined ? `${URL.API_URL}/invitation/search/${serviceId}/${companyId}?page=${page}${otherProps}` : `${URL.API_URL}/invitation/search/${serviceId}/${companyId}?page=${page}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.get(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      return dispatch(searchInvitationsSuccess(response));
    }).catch((error) => {
      dispatch(searchInvitationsFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(searchInvitations(serviceId, companyId, page, otherProps), dispatch);
        }
      }
      checkAuthState(error.response.status);
      return dispatch(hasInvitationsFailure(error));
    });
  };
}

function searchInvitationsSuccess(response: any): TInvitations {
  return {
    type: Actions.SEARCH_INVITATIONS_SUCCESS,
    payload: response.data
  };
}

function searchInvitationsFailure(error: any): TInvitations {
  return {
    type: Actions.SEARCH_INVITATIONS_FAILURE,
    payload: error.response
  };
}

export function createInvitation(payload: any, serviceId: number, companyId: number, page: number, queryString: string): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/invitation/create`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    return axios.post(url, payload, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      dispatch(createInvitationSuccess(response));
      dispatch(searchInvitations(serviceId, companyId, page, queryString));
    }).catch((error) => {
      dispatch(createInvitationFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(createInvitation(payload, serviceId, companyId, page, queryString), dispatch);
        }
      }
      checkAuthState(error.response.status);
    });
  };
}

function createInvitationSuccess(response: any): TInvitations {
  return {
    type: Actions.CREATE_INVITATION_SUCCESS,
    payload: response.data
  };
}

function createInvitationFailure(error: any): TInvitations {
  return {
    type: Actions.CREATE_INVITATION_FAILURE,
    payload: error.response
  };
}

export function getLicenses(serviceCode: string, companyId: number): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/license/list_by_company/${serviceCode}/${companyId}`;

  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    return axios.get(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      dispatch(getLicensesSuccess(response));
    }).catch((error) => {
      dispatch(getLicensesFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(getLicenses(serviceCode, companyId), dispatch);
        }
      }
      checkAuthState(error.response.status);
    });
  };
}

function getLicensesSuccess(response: any): TInvitations {
  return {
    type: Actions.GET_LICENSE_SUCCESS,
    payload: response.data
  };
}

function getLicensesFailure(error: any): TInvitations {
  return {
    type: Actions.GET_LICENSE_FAILURE,
    payload: error.response
  };
}

export function deleteInvitation(invitationId: number, serviceId: number, companyId: number, page: number, queryString: string): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/invitation/delete/${invitationId}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    return axios.delete(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      dispatch(deleteInvitationSuccess(response));
      dispatch(searchInvitations(serviceId, companyId, page, queryString));
    }).catch((error) => {
      dispatch(deleteInvitationFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(deleteInvitation(invitationId, serviceId, companyId, page, queryString), dispatch);
        }
      }
      checkAuthState(error.response.status);
    });
  };
}

function deleteInvitationSuccess(response: any): TInvitations {
  return {
    type: Actions.DELETE_INVITATION_SUCCESS,
    payload: response.data
  };
}

function deleteInvitationFailure(error: any): TInvitations {
  return {
    type: Actions.DELETE_INVITATION_FAILURE,
    payload: error.response
  };
}

export function sendInvitation(arrayInvitationsId: any, serviceId: number, companyId: number, page: number, queryString: string): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/invitation/send/`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    return axios.post(url, arrayInvitationsId, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      dispatch(sendInvitationSuccess(response));
      dispatch(searchInvitations(serviceId, companyId, page, queryString));
    }).catch((error) => {
      dispatch(sendInvitationFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(sendInvitation(arrayInvitationsId, serviceId, companyId, page, queryString), dispatch);
        }
      }
      checkAuthState(error.response.status);
    });
  };
}

function sendInvitationSuccess(response: any): TInvitations {
  return {
    type: Actions.SEND_INVITATION_SUCCESS,
    payload: response.data
  };
}

function sendInvitationFailure(error: any): TInvitations {
  return {
    type: Actions.SEND_INVITATION_FAILURE,
    payload: error.response
  };
}

export function undoInvitation(inviteId: number, serviceId: number, companyId: number, page: number, queryString: string): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/invitation/undo/${inviteId}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    return axios.delete(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      dispatch(undoInvitationSuccess(response));
      dispatch(searchInvitations(serviceId, companyId, page, queryString));
    }).catch((error) => {
      dispatch(undoInvitationFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(undoInvitation(inviteId, serviceId, companyId, page, queryString), dispatch);
        }
      }
      checkAuthState(error.response.status);
    });
  };
}

function undoInvitationSuccess(response: any): TInvitations {
  return {
    type: Actions.UNDO_INVITATION_SUCCESS,
    payload: response.data
  };
}

function undoInvitationFailure(error: any): TInvitations {
  return {
    type: Actions.UNDO_INVITATION_FAILURE,
    payload: error.response
  };
}

export function getEnvironment(serviceId: number, companyId: number): ThunkAction<Promise<any>, AppState, {}, AnyAction> {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/environment/${serviceId}/${companyId}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.get(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      return dispatch(getEnvironmentSuccess(response));
    }).catch((error) => {
      dispatch(getEnvironmentFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(getEnvironment(serviceId, companyId), dispatch);
        }
      }
    });
  };
}

function getEnvironmentSuccess(response: any): TInvitations {
  return {
    type: "GET_ENV_SUCCESS",
    payload: response.data
  };
}

function getEnvironmentFailure(error: any): TInvitations {
  return {
    type: "GET_ENV_FAILURE",
    payload: error.response
  };
}

export function setEnvironment(baseUrl: string, serviceId: number, companyId: number): ThunkAction<Promise<any>, AppState, {}, AnyAction> {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/environment/${serviceId}/${companyId}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.post(url, baseUrl, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      return dispatch(setEnvironmentSuccess(response));
    }).catch((error) => {
      dispatch(setEnvironmentFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(setEnvironment(baseUrl, serviceId, companyId), dispatch);
        }
      }
    });
  };
}

function setEnvironmentSuccess(response: any): TInvitations {
  return {
    type: "SET_ENV_SUCCESS",
    payload: response.data
  };
}

function setEnvironmentFailure(error: any): TInvitations {
  return {
    type: "SET_ENV_FAILURE",
    payload: error.response
  };
}

export function adoptInvitationToken(bearerToken:string, invToken:string) {
  // Vecchia modalità
  const url = `${URL.API_URL}/invitation/use-invitation/${invToken}`;
  const AuthStr = 'Bearer '.concat(bearerToken);

  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: { Authorization: AuthStr } })
      .then((response) => { resolve(response); })
      .catch((err) => { reject(err); });
  });
}
