import React from "react";

import { Badge, Spinner } from "reactstrap";
import { dictionaryGet } from "../../../common/functions";

const SpinnerBadge = (dictionary: any, dictionaryMessage: string) => {
  return (
    <Badge color="light">
      <Spinner size="sm" color="primary" />
      <span style={{ color: "#0D6EFD", paddingLeft: "0.5rem" }}>
        {dictionaryGet(dictionary, dictionaryMessage)}{"..."}{" "}
      </span>
    </Badge>
  );
};

export default SpinnerBadge