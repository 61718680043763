import Cookies from 'universal-cookie';
import moment from 'moment';
import { COOKIE_ACCESS, COOKIE_REFRESH, ID_TOKEN_COOKIE } from './constants';
const cookie = new Cookies();

export function setAccessTokenCookie(token: string) {
  cookie.set(COOKIE_ACCESS, token);
}

export function getAccessTokenCookie() {
  return cookie.get(COOKIE_ACCESS);
}

export function removeAccessTokenCookie() {
  cookie.remove(COOKIE_ACCESS, { path: '/' });
  document.location.href = '/';
}

export function removeAccessTokenCookieWithoutRedirect() {
  cookie.remove(COOKIE_ACCESS, { path: '/' });
}

export function setRefreshTokenCookie(token: string) {
  cookie.set(COOKIE_REFRESH, token);
}

export function getRefreshTokenCookie() {
  return cookie.get(COOKIE_REFRESH);
}

export function removeRefreshTokenCookie() {
  cookie.remove(COOKIE_REFRESH, { path: '/' });
}

// export function createHeader() {
//   const AuthStr = HEADER.BEARER.concat(getJWTCookie());
//   return AuthStr;
// }

export function formatDateFromTimestamp(timestamp: any) {
  return moment(timestamp).format('DD/MM/YYYY');
}

export function checkAuthState(httpStatus: number) {
  if (httpStatus === 403) {
    removeAccessTokenCookie();
    removeRefreshTokenCookie();
  }
}
export function dictionaryGet(dictionary:any, value:string) {
  let a = "";
  if (dictionary!=null) {
    a = value.split('.').reduce((o,i)=>o[i], dictionary);
  }
  return a;
}

export const getParameterByName = (name:string, url:string = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return '';
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export function setIdTokenCookie(token:string) {
  cookie.set(ID_TOKEN_COOKIE, token);
}
export function getIdTokenCookie() {
  return cookie.get(ID_TOKEN_COOKIE);
}
export function removeIdTokenCookie() {
  cookie.remove(ID_TOKEN_COOKIE, { path: '/' });
}

export function stringTemplateReplace(template:string, value:any) {
  let textbody = template.replace(/\${[^{}]+}/g, function(key:any){
    return value[key.replace(/[${}]+/g, "")] || "";
  });
  return textbody;
}

export const translationStringReplace = (stringKey: string, translationValueString: string, nameToBeReplaced: any | undefined, applyQuotes: boolean = false) => {
  // Explicitly add quotes if not applying quotes
  const processedName = !applyQuotes ? nameToBeReplaced : `"${nameToBeReplaced}"`;

  let key = { [stringKey]: processedName };
  let message = stringTemplateReplace(translationValueString, key);

  return message;
};
